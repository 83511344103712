
// FixtureList.js

import React from 'react';
import MatchRow from './MatchRow';
import PropTypes from 'prop-types';

const FixtureList = ({ matches, logos }) => {
  if (!matches.length) {
    return <div className="text-center py-4">Gösterilecek maç bulunmamaktadır.</div>;
  }

  return (
    <div className="fixture-list space-y-2">
      {matches.map((match) => (
        <MatchRow key={match.id} match={match} logos={logos} /> 
      ))}
    </div>
  );
};

FixtureList.propTypes = {
  matches: PropTypes.array.isRequired,
  logos: PropTypes.shape({
    teams: PropTypes.object.isRequired,
    tournaments: PropTypes.object.isRequired,
  }).isRequired,
};

export default FixtureList;
