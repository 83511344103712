import React from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import MenuBar from "../components/MenuBar";
import Fixture from "./Fixture";
import Statistics from "./Statistics";
import LiveScores from "./LiveScores";
import PlayerStatistics from "./PlayerStatistics";
import Team from "./Team";
import Transfers from "./Transfers";

const TeamDashboard = () => {
  const { teamSlug, teamId } = useParams();

  const menuItems = [
    {
      title: "Fikstür",
      path: `/${teamSlug}/${teamId}/fixture`
    },
    {
      title: "Canlı Puan Durumu",
      path: `/${teamSlug}/${teamId}/live-scores`
    },
    {
      title: "İstatistik",
      path: `/${teamSlug}/${teamId}/statistics`
    },
 
    {
      title: "Kadro",
      path: `/${teamSlug}/${teamId}/squad`
    },
    {
      title: "Transferler",
      path: `/${teamSlug}/${teamId}/transfers`
    }
  ];

  return (
    <div>
      <MenuBar menuItems={menuItems} />
      <div className="content-container">
        <Routes>
          <Route path="/fixture" element={<Fixture />} />
          <Route path="/live-scores" element={<LiveScores />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/player-statistics" element={<PlayerStatistics />} />
          <Route path="/squad" element={<Team />} />
          <Route path="/transfers" element={<Transfers />} />
          {/* Varsayılan olarak Fixture'a yönlendir */}
          <Route path="/" element={<Navigate to="fixture" />} />
        </Routes>
      </div>
    </div>
  );
};

export default TeamDashboard; 