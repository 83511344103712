import axios from 'axios';
import { config } from '../config';

const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    'X-RapidAPI-Host': 'footapi7.p.rapidapi.com',
    'X-RapidAPI-Key': config.API_KEY
  }
});

const countryToCode = {
  'turkey': 'tr',
  'england': 'gb',
  'spain': 'es',
  'germany': 'de',
  'france': 'fr',
  'italy': 'it',
  'netherlands': 'nl',
  'portugal': 'pt',
  'brazil': 'br',
  'argentina': 'ar',
  'croatia': 'hr',
  'belgium': 'be',
  'denmark': 'dk',
  'poland': 'pl',
  'wales': 'gb-wls',
  'scotland': 'gb-sct',
  'switzerland': 'ch',
  'austria': 'at',
  'ukraine': 'ua',
  'russia': 'ru',
  'czech republic': 'cz',
  'sweden': 'se',
  'norway': 'no',
  'finland': 'fi',
  'ireland': 'ie',
  'romania': 'ro',
  'hungary': 'hu',
  'greece': 'gr',
  'serbia': 'rs',
  'morocco': 'ma',
  'senegal': 'sn',
  'algeria': 'dz',
  'nigeria': 'ng',
  'cameroon': 'cm',
  'egypt': 'eg',
  'tunisia': 'tn',
  'japan': 'jp',
  'south korea': 'kr',
  'iran': 'ir',
  'saudi arabia': 'sa',
  'australia': 'au',
  'united states': 'us',
  'mexico': 'mx',
  'canada': 'ca',
  'colombia': 'co',
  'uruguay': 'uy',
  'chile': 'cl',
  'peru': 'pe',
  'ecuador': 'ec',
  'paraguay': 'py',
  'venezuela': 've',
  'bolivia': 'bo',
  'ivory coast': 'ci',
  'ghana': 'gh',
  'mali': 'ml',
  'burkina faso': 'bf',
  'albania': 'al',
  'montenegro': 'me',
  'bosnia and herzegovina': 'ba',
  'macedonia': 'mk',
  'slovenia': 'si',
  'slovakia': 'sk',
  'iceland': 'is',
  'estonia': 'ee',
  'latvia': 'lv',
  'lithuania': 'lt',
  'belarus': 'by',
  'moldova': 'md',
  'azerbaijan': 'az',
  'georgia': 'ge',
  'armenia': 'am',
  'kazakhstan': 'kz',
  'uzbekistan': 'uz',
  'china': 'cn',
  'india': 'in',
  'thailand': 'th',
  'vietnam': 'vn',
  'indonesia': 'id',
  'malaysia': 'my',
  'philippines': 'ph',
  'singapore': 'sg',
  'new zealand': 'nz'
};

export const fetchTeamMatchesForSeason = async (teamId, season = '24/25') => {
  try {
    const [previousMatches0, nextMatches0, previousMatches1, nextMatches1] = await Promise.all([
      api.get(`/team/${teamId}/matches/previous/0`),
      api.get(`/team/${teamId}/matches/next/0`),
      api.get(`/team/${teamId}/matches/previous/1`),
      api.get(`/team/${teamId}/matches/next/1`)
    ]);

    const allMatches = [
      ...(previousMatches0.data?.events || []),
      ...(nextMatches0.data?.events || []),
      ...(previousMatches1.data?.events || []),
      ...(nextMatches1.data?.events || [])
    ].filter(match => match.season?.name?.includes(season));

    // Maçları tarihe göre sırala
    const sortedMatches = allMatches.sort((a, b) => {
      return new Date(a.startTimestamp * 1000) - new Date(b.startTimestamp * 1000);
    });

    // Tekrar eden maçları filtrele
    const uniqueMatches = sortedMatches.filter((match, index, self) =>
      index === self.findIndex((m) => m.id === match.id)
    );

    return uniqueMatches;
  } catch (error) {
    console.error('Sezon maçları alınamadı:', error);
    throw error;
  }
};

export const fetchMatchDetails = async (matchId) => {
  const response = await api.get(`/match/${matchId}`);
  return response.data;
};

export const fetchMatchStatistics = async (matchId) => {
  const response = await api.get(`/match/${matchId}/statistics`);
  return response.data;
};

export const fetchTeamLogo = async (teamId) => {
  try {
    // Önce public/logos/team klasöründen logo almayı dene
    const localLogo = `/logos/team/${teamId}.png`;
    try {
      const response = await fetch(localLogo);
      if (response.ok) {
        return localLogo;
      }
    } catch (e) {
      // Logo local'de yok, API'dan indirelim
    }

    // API'dan logo indir
    const response = await axios.get(`${config.API_URL}/team/${teamId}/image`, {
      headers: {
        'X-RapidAPI-Key': config.API_KEY,
        'X-RapidAPI-Host': config.API_HOST,
      },
      responseType: 'blob'
    });

    if (response.data) {
      return URL.createObjectURL(response.data);
    }
    
    throw new Error('Logo alınamadı');
  } catch (error) {
    console.error(`Takım logosu alınamadı (ID: ${teamId}):`, error);
    return '/default-team-logo.png';
  }
};

export const fetchAndSaveTournamentLogo = async (tournamentId) => {
  try {
    // Önce local'de logo var mı kontrol et
    const localLogo = `/logos/tournament/${tournamentId}.png`;
    try {
      const response = await fetch(localLogo);
      if (response.ok) {
        return localLogo;
      }
    } catch (e) {
      // Logo local'de yok, API'dan indirelim
    }

    // API'dan logo indir
    const response = await api.get(`/tournament/${tournamentId}/image`, {
      responseType: 'blob'
    });
    
    if (response.data) {
      // Backend'e logo kaydetme isteği
      const formData = new FormData();
      formData.append('logo', response.data);
      formData.append('tournamentId', tournamentId);
      
      await axios.post(`${config.BACKEND_URL}/api/save-tournament-logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return `/logos/tournament/${tournamentId}.png`;
    }
    
    throw new Error('Turnuva logosu alınamadı');
  } catch (error) {
    console.error(`Turnuva logosu alınamadı (ID: ${tournamentId}):`, error);
    return '/default-tournament-logo.png';
  }
};

// Geriye dönük uyumluluk için alias'lar
export const fetchTournamentLogo = fetchAndSaveTournamentLogo;

export const fetchCountryFlag = async (country) => {
  try {
    if (!country || !country.alpha2) {
      console.warn('Ülke bilgisi eksik:', country);
      return '/default-flag.png';
    }

    const code = country.alpha2.toLowerCase();
    
    // RapidAPI'den bayrak çek
    const response = await axios.get(`${config.API_URL}/img/flag/${code}`, {
      headers: {
        'X-RapidAPI-Key': config.API_KEY,
        'X-RapidAPI-Host': config.API_HOST
      },
      responseType: 'blob'
    });

    if (response.data) {
      return URL.createObjectURL(response.data);
    }

    throw new Error('Bayrak alınamadı');
  } catch (error) {
    console.error(`Ülke bayrağı alınamadı:`, error);
    return '/default-flag.png';
  }
};
  