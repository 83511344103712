import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TeamDashboard from "./pages/TeamDashboard";
import League from "./pages/League";
import MatchDetail from "./pages/MatchDetail";
import PlayerDetail from "./pages/PlayerDetail";
import Live from './pages/Live';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/live" />} />
        <Route path="/live" element={<Live />} />
        <Route path="/match/:matchId" element={<MatchDetail />} />
        <Route path="/:teamSlug/:teamId/*" element={<TeamDashboard />} />
        <Route path="/league" element={<Navigate to="/league/champions-leauge/7" />} />
        <Route path="/league/:leaugeSlug/:leagueId" element={<League />} />
        <Route path="/player/:playerId" element={<PlayerDetail />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
