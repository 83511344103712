import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Lottie from "react-lottie";
import { fetchCountryFlag } from "../services/api";

// Piyasa değeri formatı için yardımcı fonksiyon
const formatMarketValue = (value) => {
  if (!value || value === "N/A") return "-";
  
  const million = 1000000;
  const thousand = 1000;
  
  if (value >= million) {
    return `${(value / million).toFixed(1)}M €`;
  } else if (value >= thousand) {
    return `${(value / thousand).toFixed(0)}K €`;
  }
  
  return `${value} €`;
};

const Team = () => {
  const { teamSlug, teamId } = useParams();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [countryFlags, setCountryFlags] = useState({});

  const API_KEY = 'a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023';
  const API_HOST = 'footapi7.p.rapidapi.com';

  // Fetch the Lottie animation file
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch('/load.json');
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Error loading animation:", error);
      }
    };

    fetchAnimationData();
  }, []);

  // Lottie animation configuration
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Function to fetch player data
  const fetchPlayerData = async () => {
    try {
      const options = {
        method: "GET",
        url: `https://footapi7.p.rapidapi.com/api/team/${teamId}/players`,
        headers: {
          "x-rapidapi-key": API_KEY,
          "x-rapidapi-host": API_HOST,
        },
      };

      const response = await axios.request(options);
      setPlayers(response.data.players);

      // Ülke bayraklarını getir
      const flags = {};
      for (const player of response.data.players) {
        const nationality = player.player.country;
        if (nationality && !flags[nationality.alpha2]) {
          flags[nationality.alpha2] = await fetchCountryFlag(nationality);
        }
      }
      setCountryFlags(flags);

    } catch (error) {
      if (error.response && error.response.status === 429) {
        setError("Too many requests! Please try again later.");
      } else {
        setError("An error occurred while fetching players.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch player data when the component loads
  useEffect(() => {
    fetchPlayerData();
  }, [teamId]); // Re-fetch data if teamId changes

  // Pozisyon çevirisi için yardımcı fonksiyon
  const translatePosition = (position) => {
    const positions = {
      'F': 'Forvet',
      'M': 'Orta Saha',
      'D': 'Defans',
      'G': 'Kaleci'
    };
    return positions[position] || position;
  };

  // Ayak tercihi çevirisi için yardımcı fonksiyon
  const translateFoot = (foot) => {
    if (!foot) return 'Belirtilmemiş';
    
    const feet = {
      'right': 'Sağ',
      'left': 'Sol',
      'both': 'İki Ayak'
    };
    return feet[foot.toLowerCase()] || foot;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-[#1a1a1a] to-[#2d2d2d]">
        {animationData ? (
          <Lottie options={defaultOptions} height={150} width={150} />
        ) : (
          <div className="text-white">Yükleniyor...</div>
        )}
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="min-h-screen mt-4">
      <div className="w-full">
        {/* Forma Resmi */}
      

        <div className="bg-black rounded-lg shadow-xl overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-[#0d0d0d]">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Oyuncu
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Pozisyon
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Tercih Ayağı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Piyasa Değeri
                </th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, index) => (
                <tr 
                  key={player.player.id}
                  className={`${
                    index % 2 === 0 ? 'bg-[#0d0d0d]' : 'bg-[#171717]'
                  } hover:bg-[#3a3a3a] transition-colors duration-150`}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link 
                      to={`/player/${player.player.id}`}
                      className="flex items-center text-white hover:text-blue-400 transition-colors"
                    >
                      
                      <span 
                        className="px-2 py-1 rounded-full text-sm font-bold mr-3 w-8 h-8 inline-flex items-center justify-center"
                        style={{
                          backgroundColor: player.player.team.teamColors.primary,
                          color: player.player.team.teamColors.secondary,
                          border: `2px solid ${player.player.team.teamColors.secondary}`
                        }}
                      >
                        {player.player.jerseyNumber}
                      </span>
                      {player.player.country && (
                        <img
                          src={countryFlags[player.player.country.alpha2]}
                          alt={player.player.country.name}
                          className="w-8 h-8 rounded-full mr-3 object-cover"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/default-flag.png';
                          }}
                        />
                      )}
                      {player.player.name}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {translatePosition(player.player.position)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {translateFoot(player.player.preferredFoot)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {formatMarketValue(player.player.proposedMarketValue)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Team;
