import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../config';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, CategoryScale, LinearScale, Title } from 'chart.js';
import { Radar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  RadialLinearScale,
  Filler,
  Legend
);

const PlayerDetail = () => {
  const { playerId } = useParams();
  const [playerDetails, setPlayerDetails] = useState(null);
  const [playerAttributes, setPlayerAttributes] = useState(null);
  const [lastRatings, setLastRatings] = useState(null);
  const [playerSummary, setPlayerSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [penaltyStats, setPenaltyStats] = useState(null);
  const [transferHistory, setTransferHistory] = useState(null);
  const [seasonStats, setSeasonStats] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [nationalStats, setNationalStats] = useState(null);
  const [countryFlags, setCountryFlags] = useState(null);
  const [playerCharacteristics, setPlayerCharacteristics] = useState(null);

  
  // Lazy loading için referanslar
  const penaltyRef = useRef(null);
  const transferRef = useRef(null);
  const seasonRef = useRef(null);
  const nationalRef = useRef(null);

  // Lazy loading için state'ler
  const [loadPenalty, setLoadPenalty] = useState(false);
  const [loadTransfer, setLoadTransfer] = useState(false);
  const [loadSeason, setLoadSeason] = useState(false);
  const [loadNational, setLoadNational] = useState(false);

  // Intersection Observer kurulumu
  useEffect(() => {
    const handleScroll = () => {
      const penaltyElement = penaltyRef.current;
      const transferElement = transferRef.current;
      const seasonElement = seasonRef.current;
      const nationalElement = nationalRef.current;

      const isElementInViewport = (el) => {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        );
      };

      if (penaltyElement && isElementInViewport(penaltyElement) && !loadPenalty) {
        console.log('Penaltı bölümü görünür oldu');
        setLoadPenalty(true);
      }

      if (transferElement && isElementInViewport(transferElement) && !loadTransfer) {
        console.log('Transfer bölümü görünür oldu');
        setLoadTransfer(true);
      }

      if (seasonElement && isElementInViewport(seasonElement) && !loadSeason) {
        console.log('Sezon bölümü görünür oldu');
        setLoadSeason(true);
      }

      if (nationalElement && isElementInViewport(nationalElement) && !loadNational) {
        console.log('Milli takım bölümü görünür oldu');
        setLoadNational(true);
      }
    };

    // İlk yüklemede kontrol et
    setTimeout(handleScroll, 1000);

    // Scroll event listener ekle
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadPenalty, loadTransfer, loadSeason, loadNational]);

  // İlk yükleme için temel veriler
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [detailsResponse, attributesResponse, ratingsResponse, summaryResponse, characteristicsResponse] = await Promise.all([
          axios.get(`${config.API_URL}/player/${playerId}`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/player/${playerId}/attribute`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/player/${playerId}/tournament/7/season/36886/last-ratings`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/player/${playerId}/summary`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/player/${playerId}/characteristics`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
        ]);

        setPlayerDetails(detailsResponse.data.player);
        const countryCode = detailsResponse.data.player.country.alpha2.toLowerCase();
        setCountryFlags(`https://flagcdn.com/w160/${countryCode}.png`);
        setPlayerAttributes(attributesResponse.data);
        setLastRatings(ratingsResponse.data.lastRatings);
        setPlayerSummary(summaryResponse.data);
        setPlayerCharacteristics(characteristicsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [playerId]);
  

 
  // Penaltı istatistikleri yükleme
  useEffect(() => {
    console.log('Penaltı yükleme durumu:', loadPenalty);
    if (loadPenalty) {
      const fetchPenaltyStats = async () => {
        try {
          console.log('Penaltı verileri yükleniyor...');
          const response = await axios.get(`${config.API_URL}/player/${playerId}/penalty`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          });
          console.log('Penaltı verileri yüklendi:', response.data);
          setPenaltyStats(response.data);
        } catch (error) {
          console.error('Penaltı verilerini yüklerken hata:', error);
        }
      };
      fetchPenaltyStats();
    }
  }, [loadPenalty, playerId]);

  // Transfer geçmişi yükleme
  useEffect(() => {
    console.log('Transfer yükleme durumu:', loadTransfer);
    if (loadTransfer) {
      const fetchTransferHistory = async () => {
        try {
          console.log('Transfer verileri yükleniyor...');
          const response = await axios.get(`${config.API_URL}/player/${playerId}/transfer`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          });
          console.log('Transfer verileri yüklendi:', response.data);
          setTransferHistory(response.data);
        } catch (error) {
          console.error('Transfer verilerini yüklerken hata:', error);
        }
      };
      fetchTransferHistory();
    }
  }, [loadTransfer, playerId]);

  // Sezon istatistikleri yükleme
  useEffect(() => {
    console.log('Sezon yükleme durumu:', loadSeason);
    if (loadSeason) {
      const fetchSeasonStats = async () => {
        try {
          console.log('Sezon verileri yükleniyor...');
          const response = await axios.get(`${config.API_URL}/player/${playerId}/statistics/season`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          });
          console.log('Sezon verileri yüklendi:', response.data);
          setSeasonStats(response.data);
          if (response.data.uniqueTournamentSeasons.length > 0) {
            const firstTournament = response.data.uniqueTournamentSeasons[0];
            setSelectedTournament(firstTournament.uniqueTournament);
            setSelectedSeason(firstTournament.seasons[0]);
          }
        } catch (error) {
          console.error('Sezon verilerini yüklerken hata:', error);
        }
      };
      fetchSeasonStats();
    }
  }, [loadSeason, playerId]);

  // Milli takım istatistikleri yükleme
  useEffect(() => {
    console.log('Milli takım yükleme durumu:', loadNational);
    if (loadNational) {
      const fetchNationalStats = async () => {
        try {
          console.log('Milli takım verileri yükleniyor...');
          const response = await axios.get(`${config.API_URL}/player/${playerId}/statistics/national`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          });
          console.log('Milli takım verileri yüklendi:', response.data);

          // Yeni API yapısına göre veriyi düzenle
          if (response.data.statistics && response.data.statistics.length > 0) {
            const nationalData = {
              team: response.data.statistics[0].team,
              statistics: [{
                appearances: response.data.statistics[0].appearances,
                goals: response.data.statistics[0].goals,
                assists: 0, // API'de yok
                rating: 0, // API'de yok
                lineups: 0, // API'de yok
                yellowCards: 0, // API'de yok
                redCards: 0, // API'de yok
              }],
              firstMatchTimestamp: response.data.statistics[0].debutTimestamp,
              lastMatchTimestamp: Date.now() / 1000 // Şu anki tarih
            };
            setNationalStats(nationalData);
          } else {
            setNationalStats({
              team: {
                id: 0,
                name: 'Milli Takım Verisi Yok'
              },
              statistics: [],
              firstMatchTimestamp: null,
              lastMatchTimestamp: null
            });
          }
        } catch (error) {
          console.error('Milli takım verilerini yüklerken hata:', error);
          setNationalStats({
            team: {
              id: 0,
              name: 'Milli Takım Verisi Yok'
            },
            statistics: [],
            firstMatchTimestamp: null,
            lastMatchTimestamp: null
          });
        }
      };
      fetchNationalStats();
    }
  }, [loadNational, playerId]);

  const fetchStatistics = async (tournamentId, seasonId) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/player/${playerId}/tournament/${tournamentId}/season/${seasonId}/statistics`,
        {
          headers: {
            'X-RapidAPI-Key': config.API_KEY,
            'X-RapidAPI-Host': config.API_HOST,
          },
        }
      );
      setStatistics(response.data.statistics);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  useEffect(() => {
    if (selectedTournament && selectedSeason) {
      fetchStatistics(selectedTournament.id, selectedSeason.id);
    }
  }, [selectedTournament, selectedSeason]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const formatMarketValue = (value) => {
    if (value >= 1000000) {
      return `€${(value / 1000000).toFixed(2)}M`;
    } else if (value >= 1000) {
      return `€${(value / 1000).toFixed(2)}K`;
    }
    return `€${value}`;
  };

  const renderAttributeChart = (attributes) => {
    const data = {
      labels: ['Hücum', 'Teknik', 'Taktik', 'Savunma', 'Yaratıcılık'],
      datasets: [
        {
          label: 'Oyuncu Özellikleri',
          data: [
            attributes.attacking,
            attributes.technical,
            attributes.tactical,
            attributes.defending,
            attributes.creativity
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 2,
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        }
      ]
    };

    const options = {
      scales: {
        r: {
          angleLines: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          pointLabels: {
            color: 'rgb(255, 255, 255)',
            font: {
              size: 12
            }
          },
          ticks: {
            color: 'rgb(255, 255, 255)',
            backdropColor: 'transparent'
          },
          suggestedMin: 0,
          suggestedMax: 100
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    };

    return (
      <div className="w-full h-full flex items-center justify-center p-4">
        <Radar data={data} options={options} />
      </div>
    );
  };

  const renderPerformanceChart = (ratings, summary) => {
    if (!summary || !summary.summary) {
      return (
        <div className="w-full h-full flex items-center justify-center p-4">
          <div className="text-gray-400">Performans verisi bulunamadı</div>
        </div>
      );
    }

    const performanceData = summary.summary
      .filter(item => item.type === 'event' && item.value)
      .map(item => ({
        timestamp: item.timestamp,
        rating: parseFloat(item.value),
        tournament: summary.uniqueTournamentsMap?.[item.uniqueTournamentId]?.name || 'Bilinmeyen Turnuva'
      }));

    if (performanceData.length === 0) {
      return (
        <div className="w-full h-full flex items-center justify-center p-4">
          <div className="text-gray-400">Performans verisi bulunamadı</div>
        </div>
      );
    }

    const absenceData = summary.summary
      .filter(item => (item.type === 'injury' || item.type === 'missing') && item.timestamp)
      .map(item => ({
        timestamp: item.timestamp,
        type: item.type,
        value: item.type === 'injury' ? item.value : null
      }));

    const data = {
      labels: performanceData.map(item => {
        const date = new Date(item.timestamp * 1000);
        return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'short' });
      }),
      datasets: [
        {
          label: 'Performans Puanı',
          data: performanceData.map(item => item.rating),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          tension: 0.3,
          pointRadius: 4,
          pointHoverRadius: 6,
        },
        {
          label: 'Sakatlık/Maç Kaçırma',
          data: absenceData.map(item => ({
            x: new Date(item.timestamp * 1000).toLocaleDateString('tr-TR', { day: 'numeric', month: 'short' }),
            y: null,
            type: item.type,
            duration: item.value
          })),
          pointStyle: 'triangle',
          pointRadius: 8,
          pointHoverRadius: 10,
          pointBackgroundColor: (context) => {
            const value = context.raw;
            return value?.type === 'injury' ? 'rgb(239, 68, 68)' : 'rgb(234, 179, 8)';
          },
          showLine: false,
        }
      ]
    };

    const options = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: false,
          min: Math.min(...performanceData.map(item => item.rating)) - 0.5,
          max: Math.max(...performanceData.map(item => item.rating)) + 0.5,
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          ticks: {
            color: 'rgb(255, 255, 255)'
          }
        },
        x: {
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          },
          ticks: {
            color: 'rgb(255, 255, 255)'
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: 'rgb(255, 255, 255)'
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              const value = context.raw;
              if (typeof value === 'object' && value?.type) {
                if (value.type === 'injury') {
                  const days = Math.floor(parseInt(value.duration) / 86400);
                  return `Sakatlık (${days} gün)`;
                }
                return 'Maç Kaçırma';
              }
              return `Puan: ${value}`;
            },
            afterLabel: function(context) {
              const datasetIndex = context.datasetIndex;
              if (!ratings || !ratings[context.dataIndex]) return [];
              const rating = ratings[context.dataIndex];
              return [
                `Rakip: ${rating.opponent?.name || 'Bilinmiyor'}`,
                `Skor: ${rating.event?.homeScore?.current || 0}-${rating.event?.awayScore?.current || 0}`,
                `Turnuva: ${rating.event?.tournament?.uniqueTournament?.name || 'Bilinmiyor'}`
              ];
            }
          }
        }
      }
    };

    return (
      <div className="w-full h-full flex items-center justify-center p-4">
        <Line data={data} options={options} />
      </div>
    );
  };

  const LoadingPlaceholder = () => (
    <div className="animate-pulse">
      <div className="h-8 bg-[#2a2a2a] rounded w-1/4 mb-4"></div>
      <div className="grid grid-cols-2 gap-8">
        <div className="h-40 bg-[#2a2a2a] rounded"></div>
        <div className="h-40 bg-[#2a2a2a] rounded"></div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0d0d0d] flex items-center justify-center">
        <div className="text-white text-xl">Yükleniyor...</div>
      </div>
    );
  }

  if (!playerDetails) {
    return (
      <div className="min-h-screen bg-[#0d0d0d] flex items-center justify-center">
        <div className="text-white text-xl">Oyuncu detayları bulunamadı.</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0d0d0d]">
      <div style={{ width: '980px' }} className="mx-auto p-2">
        {/* Üst Bilgi Kartı */}
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-4 text-white mb-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <button 
                onClick={() => window.history.back()} 
                className="flex items-center gap-2 bg-[#2a2a2a] hover:bg-[#3a3a3a] px-3 py-1.5 rounded-lg transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
                <span>Geri</span>
              </button>
              <h1 className="text-xl font-bold">{playerDetails.name}</h1>
            </div>
          </div>
        </div>

        {/* Ana Bilgi Kartı */}
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white">
          <div className="grid grid-cols-3 gap-8">
            {/* Sol Kolon - Takım ve Oyuncu Bilgileri */}
            <div className="col-span-1">
              {playerDetails.team && (
                <div className="bg-[#2a2a2a] rounded-lg p-4 mb-4">
                  <div className="flex flex-col items-center gap-4 mb-4">
                    <div className="relative">
                      <div className="absolute w-auto top-14 left-1/2 transform -translate-x-1/2 px-2 py-0 rounded-full whitespace-nowrap">
                        <span className="text-white font-bold">{playerDetails.shortName}</span>
                      </div>
                      <div className="absolute w-16 h-16 top-[90px] left-1/2 transform -translate-x-1/2 rounded-full flex items-center justify-center " 
                        >
                        <span className="text-8xl font-bold " style={{color:'white'}}>{playerDetails.jerseyNumber}</span>
                      </div>
                      {playerAttributes && playerAttributes.averageAttributeOverviews && 
                       playerAttributes.averageAttributeOverviews.length > 0 && (
                        <div className="absolute top-[270px] left-1/2 transform -translate-x-1/2 flex items-center">
                          {(() => {
                            // Özelliklerin ortalamasını hesapla
                            const attributes = playerAttributes.averageAttributeOverviews[0];
                            const values = [
                              attributes.attacking,
                              attributes.technical, 
                              attributes.tactical,
                              attributes.defending,
                              attributes.creativity
                            ];
                            const average = values.reduce((a,b) => a + b, 0) / values.length;
                            const stars = Math.round((average / 15)); // 100 üzerinden 5'lik sisteme çevir
                            
                            return (
                              <div className="flex gap-1">
                                {[...Array(5)].map((_, i) => (
                                  <svg 
                                    key={i}
                                    className={`w-10 h-10 ${i < stars ? 'text-yellow-400' : 'text-gray-500'}`}
                                    fill="currentColor" 
                                    viewBox="0 0 53.867 53.867"
                                  >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182" />
                                  </svg>
                                ))}
                              </div>
                            );
                          })()}
                        </div>
                      )}
                      <img 
                        src="/icons/min2.png"
                        alt="Forma"
                        className="w-50 h-50 object-contain"
                      />
                     
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <img 
                        src={`/logos/team/${playerDetails.team?.id}.png`}
                        alt={playerDetails.team?.name}
                        className="w-12 h-12 object-contain bg-white rounded-full p-2"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/default-team-logo.png';
                        }}
                      />
                       <div className="text-center">
                        <h3 className="font-bold text-sm">{playerDetails.team?.name}</h3>
                        <p className="text-xs text-gray-400">{playerDetails.team?.tournament?.name}</p>
                      </div>
                      {countryFlags && (
                        <img
                          src={countryFlags}
                          alt={playerDetails.country?.name}
                          className="w-12 h-12 object-cover rounded-full border-2 border-gray-600"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/default-flag.png';
                          }}
                        />
                      )}
                      <div className="text-center">
                        <h3 className="font-bold text-sm">{playerDetails.country?.name}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                 
                  </div>
                </div>
              )}
            </div>

            {/* Orta Kolon - Kişisel Bilgiler */}
            <div className="col-span-2">
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <h3 className="text-lg font-bold mb-4">Kişisel Bilgiler</h3>
                  <div className="space-y-3">
                    {playerDetails.dateOfBirthTimestamp && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/takvim.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Doğum Tarihi</span>
                        </div>
                        <span>{formatDate(playerDetails.dateOfBirthTimestamp)}</span>
                      </div>
                    )}
                    {playerDetails.height && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/cetvel.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Boy</span>
                        </div>
                        <span>{playerDetails.height} cm</span>
                      </div>
                    )}
                    {playerDetails.preferredFoot && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/krampon.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Tercih Ettiği Ayak</span>
                        </div>
                        <span>{playerDetails.preferredFoot === 'Left' ? 'Sol' : 
                               playerDetails.preferredFoot === 'Right' ? 'Sağ' : 
                               playerDetails.preferredFoot === 'Both' ? 'İki Ayak' : 
                               playerDetails.preferredFoot}</span>
                      </div>
                    )}
                    {playerDetails.country && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/dunya.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Uyruk</span>
                        </div>
                        <span>{playerDetails.country.name}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <h3 className="text-lg font-bold mb-4">Sözleşme Bilgileri</h3>
                  <div className="space-y-3">
                    {playerDetails.contractUntilTimestamp && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/sozlesme.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Sözleşme Bitiş</span>
                        </div>
                        <span>{formatDate(playerDetails.contractUntilTimestamp)}</span>
                      </div>
                    )}
                    {playerDetails.proposedMarketValue && (
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <img src="/icons/piyasa.png" alt="Takvim" className="w-6 h-6 mr-2" />
                          <span className="text-gray-400">Piyasa Değeri</span>
                        </div>
                        <span>{formatMarketValue(playerDetails.proposedMarketValue)}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="relative">
                <img src="/icons/sahayatay.png" alt="Saha" className="w-full mt-4" />
                {playerCharacteristics?.positions?.map((position, index) => (
                  <div key={index} className="absolute" style={{
                    left: (() => {
                      // Her pozisyon için x koordinatı
                      switch(position) {
                        // Forvet pozisyonları
                        case 'CF': return '50%';  // Santrafor
                        case 'ST': return '86%';  // Santrafor
                        case 'LW': return '80%';  // Sol Kanat
                        case 'RW': return '80%';  // Sağ Kanat
                        case 'F': return '80%';   // Genel Forvet
                        
                        // Orta Saha pozisyonları
                        case 'CAM': return '50%'; // Ofansif Orta Saha
                        case 'MC': return '50%';  // Merkez Orta Saha
                        case 'CDM': return '50%'; // Defansif Orta Saha
                        case 'LM': return '20%';  // Sol Orta Saha
                        case 'RM': return '80%';  // Sağ Orta Saha
                          case 'DM': return '35%';   // Genel Orta Saha
                        case 'M': return '60%';   // Genel Orta Saha
                            case 'AM': return '70%';   // Genel Orta Saha
                        // Defans pozisyonları
                        case 'CB': return '50%';  // Stoper
                        case 'DL': return '20%';  // Sol Bek
                        case 'DR': return '20%';  // Sağ Bek
                        case 'D': return '22%';   // Genel Defans
                        
                        // Kaleci
                        case 'GK': return '10%';  // Kaleci
                        
                        default: return '50%';
                      }
                    })(),
                    top: (() => {
                      // Her pozisyon için y koordinatı
                      switch(position) {
                        // Forvet pozisyonları
                        case 'CF': return '15%';  // Santrafor
                        case 'ST': return '50%';  // Santrafor
                        case 'LW': return '20%';  // Sol Kanat
                        case 'RW': return '80%';  // Sağ Kanat
                        case 'F': return '50%';   // Genel Forvet
                        
                        // Orta Saha pozisyonları
                        case 'CAM': return '35%'; // Ofansif Orta Saha
                        case 'MC': return '50%';  // Merkez Orta Saha
                        case 'CDM': return '55%'; // Defansif Orta Saha
                        case 'LM': return '45%';  // Sol Orta Saha
                        case 'RM': return '45%';  // Sağ Orta Saha
                          case 'DM': return '50%';   // Genel Orta Saha
                        case 'M': return '50%';   // Genel Orta Saha
                          case 'AM': return '50%';   // Genel Orta Saha
                        // Defans pozisyonları
                        case 'CB': return '75%';  // Stoper
                        case 'DL': return '20%';  // Sol Bek
                        case 'DR': return '80%';  // Sağ Bek
                        case 'D': return '75%';   // Genel Defans
                        
                        // Kaleci
                        case 'GK': return '50%';  // Kaleci
                        
                        default: return '50%';
                      }
                    })(),
                    transform: 'translate(-50%, -50%)'
                  }}>
                    <div className="w-12 h-12 rounded-full bg-white border-2 flex items-center justify-center"
                         style={{
                           borderColor: playerDetails.team?.teamColors?.primary || '#fff',
                           backgroundColor: playerDetails.team?.teamColors?.secondary || '#000'
                         }}>
                      <span className="text-xs font-bold" style={{
                        color: playerDetails.team?.teamColors?.primary || '#fff'
                      }}>
                        {(() => {
                          switch(position) {
                            // Forvet pozisyonları
                            case 'CF': return 'SF';
                            case 'ST': return 'SF';
                            case 'LW': return 'SLA';
                            case 'RW': return 'SĞA';
                            case 'F': return 'F';
                            
                            // Orta Saha pozisyonları
                            case 'CAM': return 'OOS';
                            case 'MC': return 'OS';
                            case 'CDM': return 'DOS';
                            case 'LM': return 'SOS';
                            case 'RM': return 'SOS';
                            case 'M': return 'OS';
                            
                            // Defans pozisyonları
                            case 'CB': return 'ST';
                            case 'DL': return 'Sol B';
                            case 'DR': return 'Sağ B';
                            case 'D': return 'D';
                            
                            // Kaleci
                            case 'GK': return 'K';
                            
                            default: return position;
                          }
                        })()}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Özellikler Kartı */}
        {playerAttributes && playerAttributes.averageAttributeOverviews && 
         playerAttributes.averageAttributeOverviews.length > 0 && 
         playerAttributes.averageAttributeOverviews[0].attacking !== undefined && (
          <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white mt-4">
            <h2 className="text-xl font-bold mb-4">Oyuncu Özellikleri</h2>
            <div className="grid grid-cols-1 gap-8">
              {/* Radar Chart 
              <div className="bg-[#2a2a2a] rounded-lg p-4" style={{ height: '400px' }}>
                <h3 className="text-lg font-bold mb-2">Genel Özellikler</h3>
                {renderAttributeChart(playerAttributes.averageAttributeOverviews[0])}
              </div>
*/}
              {/* Özellik Detayları */}
              <div className="bg-[#2a2a2a] rounded-lg p-4">
                <h3 className="text-lg font-bold mb-4">Özellik Detayları</h3>
                <div className="space-y-4">
                 
                  {Object.entries(playerAttributes.averageAttributeOverviews[0])
                    .filter(([key]) => !['position', 'yearShift', 'id'].includes(key))
                    .map(([key, value]) => (
                      
                      <div key={key} className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400 capitalize">
                            {key === 'attacking' ? 'Bitiricilik' :
                             key === 'attacking' ? 'Şut' :
                             key === 'technical' ? 'Teknik' :
                             key === 'tactical' ? 'Taktik' :
                             key === 'defending' ? 'Top Kapma' :
                             key === 'creativity' ? 'Yaratıcılık' : key}
                          </span>
                          <span className="font-medium">{value}</span>
                        </div>
                        <div className="w-full bg-[#1a1a1a] rounded-full h-2">
                          <div
                            className="h-2 rounded-full"
                            style={{ 
                              width: `${value}%`,
                              background: `linear-gradient(90deg, ${playerDetails.team.teamColors.primary} 0%, ${playerDetails.team.teamColors.primary} 100%)`
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

  {/* Sezon İstatistikleri - Lazy Loading */}
  <div ref={seasonRef} className="min-h-[300px] bg-[#1a1a1a] rounded-lg p-6 mt-4">
          {!loadSeason ? (
            <LoadingPlaceholder />
          ) : seasonStats?.uniqueTournamentSeasons && seasonStats.uniqueTournamentSeasons.length > 0 ? (
            <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-xl font-bold mb-4">Sezon İstatistikleri</h2>
              
              {/* Turnuva ve Sezon Seçimi */}
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <label className="block text-sm text-gray-400 mb-2">Turnuva</label>
                  <select 
                    className="w-full bg-[#1a1a1a] text-white rounded-lg p-2"
                    value={selectedTournament?.id || ''}
                    onChange={(e) => {
                      const tournament = seasonStats.uniqueTournamentSeasons.find(
                        t => t.uniqueTournament.id === parseInt(e.target.value)
                      );
                      if (tournament) {
                        setSelectedTournament(tournament.uniqueTournament);
                        setSelectedSeason(tournament.seasons[0]);
                      }
                    }}
                  >
                    {seasonStats.uniqueTournamentSeasons.map(t => (
                      <option key={t.uniqueTournament.id} value={t.uniqueTournament.id}>
                        {t.uniqueTournament.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <label className="block text-sm text-gray-400 mb-2">Sezon</label>
                  <select 
                    className="w-full bg-[#1a1a1a] text-white rounded-lg p-2"
                    value={selectedSeason?.id || ''}
                    onChange={(e) => {
                      const tournament = seasonStats.uniqueTournamentSeasons.find(
                        t => t.uniqueTournament.id === selectedTournament?.id
                      );
                      if (tournament) {
                        const season = tournament.seasons.find(
                          s => s.id === parseInt(e.target.value)
                        );
                        if (season) {
                          setSelectedSeason(season);
                        }
                      }
                    }}
                  >
                    {seasonStats.uniqueTournamentSeasons
                      .find(t => t.uniqueTournament.id === selectedTournament?.id)
                      ?.seasons.map(s => (
                        <option key={s.id} value={s.id}>{s.name}</option>
                      ))}
                  </select>
                </div>
              </div>

              {/* İstatistikler */}
              {statistics && (
                <div className="grid grid-cols-3 gap-6">
                  {/* Temel İstatistikler */}
                  <div className="bg-[#2a2a2a] rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-4">Temel İstatistikler</h3>
                    <div className="space-y-3">
                      <div className="flex items-center gap-3">
                        <img src="/icons/mac.png" alt="Maç" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Maç Sayısı</span>
                          <span className="font-bold">{statistics.appearances}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/goal.png" alt="Gol" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Gol</span>
                          <span className="font-bold">{statistics.goals}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/pass.png" alt="Asist" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Asist</span>
                          <span className="font-bold">{statistics.assists}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/rating.png" alt="Puan" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Ortalama Puan</span>
                          <span className="font-bold">{statistics.rating?.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Kartlar ve Disiplin */}
                  <div className="bg-[#2a2a2a] rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-4">Kartlar ve Disiplin</h3>
                    <div className="space-y-3">
                      <div className="flex items-center gap-3">
                        <img src="/icons/yellow-card.png" alt="Sarı Kart" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Sarı Kart</span>
                          <span className="font-bold">{statistics.yellowCards}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/red-card.png" alt="Kırmızı Kart" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Kırmızı Kart</span>
                          <span className="font-bold">{statistics.redCards}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/foul.png" alt="Faul" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Yapılan Faul</span>
                          <span className="font-bold">{statistics.fouls}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Pas İstatistikleri */}
                  <div className="bg-[#2a2a2a] rounded-lg p-4">
                    <h3 className="text-lg font-bold mb-4">Pas İstatistikleri</h3>
                    <div className="space-y-3">
                      <div className="flex items-center gap-3">
                        <img src="/icons/pass.png" alt="Pas" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Toplam Pas</span>
                          <span className="font-bold">{statistics.totalPasses}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/pass.png" alt="İsabetli Pas" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">İsabetli Pas</span>
                          <span className="font-bold">{statistics.accuratePasses}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/pass.png" alt="Pas İsabet" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Pas İsabet Oranı</span>
                          <span className="font-bold">{statistics.accuratePassesPercentage?.toFixed(1)}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="text-white text-center">Sezon verisi bulunamadı</div>
          )}
        </div>

        {/* Son Maç Performansları */}
        {lastRatings && lastRatings.length > 0 && playerSummary?.summary && (
          <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white mt-4">
            <h2 className="text-xl font-bold mb-4">Son Maç Performansları</h2>
            <div className="grid grid-cols-2 gap-8">
              {/* Performans Grafiği */}
              <div className="bg-[#2a2a2a] rounded-lg p-4" style={{ height: '400px' }}>
                <h3 className="text-lg font-bold mb-2">Performans Grafiği</h3>
                {renderPerformanceChart(lastRatings, playerSummary)}
              </div>

              {/* Son Maç Detayları */}
              <div className="bg-[#2a2a2a] rounded-lg p-4">
                <h3 className="text-lg font-bold mb-4">Son Maçlar</h3>
                <div className="space-y-3">
                  {lastRatings.map((rating, index) => (
                    <div key={index} className="flex items-center gap-4 bg-[#1a1a1a] rounded-lg p-3">
                      <div className={`min-w-[40px] text-center px-2 py-1 rounded ${
                        rating.rating >= 7.5 ? 'bg-green-900/50 text-green-400' :
                        rating.rating >= 6.5 ? 'bg-yellow-900/50 text-yellow-400' :
                        rating.rating >= 5.5 ? 'bg-orange-900/50 text-orange-400' :
                        'bg-red-900/50 text-red-400'
                      }`}>
                        {rating.rating.toFixed(1)}
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center gap-2">
                          <span className="font-medium">{rating.opponent.name}</span>
                          <span className="text-sm text-gray-400">
                            ({rating.isHome ? 'E' : 'D'})
                          </span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {formatDate(rating.startTimestamp)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="font-medium">
                          {rating.event.homeScore.current}-{rating.event.awayScore.current}
                        </div>
                        <div className="text-xs text-gray-400">
                          {rating.event.tournament.uniqueTournament.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Sezon Özeti */}
        {playerSummary?.summary && playerSummary?.uniqueTournamentsMap && 
         playerSummary.summary.some(item => item.type === 'event' && item.uniqueTournamentId && playerSummary.uniqueTournamentsMap[item.uniqueTournamentId]) && (
          <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white mt-4">
            <h2 className="text-xl font-bold mb-4">Sezon Özeti</h2>
            <div className="grid grid-cols-2 gap-8">
              {/* Performans Tablosu */}
              <div className="bg-[#2a2a2a] rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-bold">Maç İstatistikleri</h3>
                  <div className="flex items-center gap-2 text-sm">
                    <span className="bg-green-900/50 text-green-400 px-2 py-0.5 rounded">7.5+</span>
                    <span className="bg-yellow-900/50 text-yellow-400 px-2 py-0.5 rounded">6.5+</span>
                    <span className="bg-orange-900/50 text-orange-400 px-2 py-0.5 rounded">5.5+</span>
                    <span className="bg-red-900/50 text-red-400 px-2 py-0.5 rounded">&lt;5.5</span>
                  </div>
                </div>
                <div className="space-y-3 max-h-[500px] overflow-y-auto pr-2">
                  {playerSummary.summary
                    .filter(item => (
                      item.type === 'event' && 
                      item.value && 
                      item.uniqueTournamentId && 
                      playerSummary.uniqueTournamentsMap[item.uniqueTournamentId]
                    ))
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((item, index) => (
                      <div key={index} className="flex items-center gap-4 bg-[#1a1a1a] rounded-lg p-3">
                        <div className={`min-w-[40px] text-center px-2 py-1 rounded ${
                          parseFloat(item.value) >= 7.5 ? 'bg-green-900/50 text-green-400' :
                          parseFloat(item.value) >= 6.5 ? 'bg-yellow-900/50 text-yellow-400' :
                          parseFloat(item.value) >= 5.5 ? 'bg-orange-900/50 text-orange-400' :
                          'bg-red-900/50 text-red-400'
                        }`}>
                          {parseFloat(item.value).toFixed(1)}
                        </div>
                        <div className="flex-1">
                          <div className="text-sm">
                            {new Date(item.timestamp * 1000).toLocaleDateString('tr-TR', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric'
                            })}
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full" style={{
                              backgroundColor: playerSummary.uniqueTournamentsMap[item.uniqueTournamentId]?.primaryColorHex || '#808080'
                            }}></div>
                            <div className="text-xs text-gray-400">
                              {playerSummary.uniqueTournamentsMap[item.uniqueTournamentId]?.name || 'Bilinmeyen Turnuva'}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Sakatlık ve Maç Kaçırma Durumları */}
              <div className="bg-[#2a2a2a] rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-bold">Sakatlık ve Maç Durumu</h3>
                  <div className="flex items-center gap-3 text-sm">
                    <div className="flex items-center gap-1">
                      <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                      <span className="text-gray-400">Sakatlık</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                      <span className="text-gray-400">Maç Kaçırma</span>
                    </div>
                  </div>
                </div>
                <div className="space-y-3 max-h-[500px] overflow-y-auto pr-2">
                  {playerSummary.summary
                    .filter(item => (
                      (item.type === 'injury' || item.type === 'missing') && 
                      item.timestamp
                    ))
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((item, index) => (
                      <div key={index} className="flex items-center gap-4 bg-[#1a1a1a] rounded-lg p-3">
                        {item.type === 'injury' ? (
                          <>
                            <div className="min-w-[40px] text-center">
                              <div className="w-3 h-3 bg-red-500 rounded-full mx-auto"></div>
                            </div>
                            <div className="flex-1">
                              <div className="text-red-400 font-medium">Sakatlık</div>
                              <div className="text-sm text-gray-400">
                                {new Date(item.timestamp * 1000).toLocaleDateString('tr-TR', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric'
                                })}
                                {item.value && ` - ${Math.floor(parseInt(item.value) / 86400)} gün`}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="min-w-[40px] text-center">
                              <div className="w-3 h-3 bg-yellow-500 rounded-full mx-auto"></div>
                            </div>
                            <div className="flex-1">
                              <div className="text-yellow-400 font-medium">Maç Kaçırma</div>
                              <div className="text-sm text-gray-400">
                                {new Date(item.timestamp * 1000).toLocaleDateString('tr-TR', {
                                  day: 'numeric',
                                  month: 'long',
                                  year: 'numeric'
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Penaltı İstatistikleri - Lazy Loading */}
        <div ref={penaltyRef} className="min-h-[300px] bg-[#1a1a1a] rounded-lg p-6 mt-4">
          {!loadPenalty ? (
            <LoadingPlaceholder />
          ) : penaltyStats?.penalties && penaltyStats.penalties.length > 0 ? (
            <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-xl font-bold mb-4">Penaltı İstatistikleri</h2>
              <div className="grid grid-cols-2 gap-8">
                {/* Sol Kolon - Kale Görseli */}
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <h3 className="text-lg font-bold mb-4">Penaltı Haritası</h3>
                  <div className="relative">
                    <img src="/icons/kale1.png" alt="Kale" className="w-full" />
                    {penaltyStats.penalties.map((penalty, index) => (
                      <div
                        key={index}
                        className="absolute"
                        style={{
                          left: `${penalty.x}%`,
                          top: `${penalty.y}%`,
                          transform: 'translate(-50%, -50%)'
                        }}
                      >
                        {penalty.outcome === 'goal' ? (
                          <img src="/icons/goal.png" alt="Gol" className="w-4 h-4" />
                        ) : (
                          <div className="w-4 h-4 flex items-center justify-center">
                            <svg className="w-4 h-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        )}
                      </div>
                    ))}
                                            

                  </div>
                <img src="/icons/cimen.png" alt="Kale" className="w-full" />

                </div>
                {/* Sağ Kolon - İstatistikler */}
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <h3 className="text-lg font-bold mb-4">Genel İstatistikler</h3>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Toplam Penaltı</span>
                      <span className="font-bold">{penaltyStats.attempts}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Gol Olan</span>
                      <span className="font-bold text-green-400">{penaltyStats.scored}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Kaçan</span>
                      <span className="font-bold text-red-400">{penaltyStats.attempts - penaltyStats.scored}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-400">Başarı Oranı</span>
                      <span className="font-bold text-blue-400">
                        {((penaltyStats.scored / penaltyStats.attempts) * 100).toFixed(1)}%
                      </span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h4 className="font-bold mb-2">Son Penaltılar</h4>
                    <div className="space-y-2 max-h-[300px] overflow-y-auto">
                      {penaltyStats.penalties.slice(0, 5).map((penalty, index) => (
                        <div key={index} className="flex items-center gap-2 bg-[#1a1a1a] p-2 rounded">
                          {penalty.outcome === 'goal' ? (
                            <div className="w-2 h-2 rounded-full bg-green-500"></div>
                          ) : (
                            <div className="w-2 h-2 rounded-full bg-red-500"></div>
                          )}
                          <div className="flex-1">
                            <div className="text-sm">{penalty.event.homeTeam.name} vs {penalty.event.awayTeam.name}</div>
                            <div className="text-xs text-gray-400">{penalty.event.tournament.uniqueTournament.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-white text-center">Penaltı verisi bulunamadı</div>
          )}
        </div>

        {/* Transfer Geçmişi - Lazy Loading */}
        <div ref={transferRef} className="min-h-[300px] bg-[#1a1a1a] rounded-lg p-6 mt-4">
          {!loadTransfer ? (
            <LoadingPlaceholder />
          ) : transferHistory?.transferHistory && transferHistory.transferHistory.length > 0 ? (
            <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-xl font-bold mb-4">Transfer Geçmişi</h2>
              <div className="relative">
                {/* Timeline çizgisi */}
                <div className="absolute left-[50%] top-0 bottom-0 w-0.5 bg-gray-700"></div>

                <div className="space-y-8">
                  {transferHistory.transferHistory
                    .filter(transfer => transfer.transferFrom || transfer.transferTo)
                    .map((transfer, index) => (
                    <div key={index} className="flex items-center gap-8">
                      {/* Gönderen Takım (Sol) */}
                      <div className="flex-1 text-right">
                        <div className="flex items-center gap-3 justify-end">
                          {transfer.transferFrom ? (
                            <img 
                              src={`/logos/team/${transfer.transferFrom.id}.png`}
                              alt={transfer.fromTeamName}
                              className="w-8 h-8 object-contain bg-white rounded-lg p-1"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/default-team-logo.png';
                              }}
                            />
                          ) : (
                            <div className="w-8 h-8 bg-gray-700 rounded-lg flex items-center justify-center">
                              <span className="text-xs">?</span>
                            </div>
                          )}
                          <div>
                            <div className="font-medium">{transfer.fromTeamName || 'Bilinmiyor'}</div>
                          </div>
                        </div>
                      </div>

                      {/* Orta nokta ve transfer detayları */}
                      <div className="relative z-10 bg-[#2a2a2a] rounded-lg p-3 min-w-[200px]">
                        <div className="text-center">
                          <div className="text-sm text-gray-400">
                            {transfer.transferDateTimestamp ? 
                              new Date(transfer.transferDateTimestamp * 1000).toLocaleDateString('tr-TR', {
                                month: 'long',
                                year: 'numeric'
                              }) : 'Tarih Bilinmiyor'
                            }
                          </div>
                          {transfer.transferFee > 0 && (
                            <div className="font-bold text-green-400 mt-1">
                              {(transfer.transferFee / 1000000).toFixed(1)}M €
                            </div>
                          )}
                          {transfer.type === 2 && (
                            <div className="text-yellow-400 text-sm mt-1">Kiralık</div>
                          )}
                        </div>
                      </div>

                      {/* Alan Takım (Sağ) */}
                      <div className="flex-1 text-left">
                        <div className="flex items-center gap-3 justify-start">
                          {transfer.transferTo ? (
                            <img 
                              src={`/logos/team/${transfer.transferTo.id}.png`}
                              alt={transfer.toTeamName}
                              className="w-8 h-8 object-contain bg-white rounded-lg p-1"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/default-team-logo.png';
                              }}
                            />
                          ) : (
                            <div className="w-8 h-8 bg-gray-700 rounded-lg flex items-center justify-center">
                              <span className="text-xs">?</span>
                            </div>
                          )}
                          <div>
                            <div className="font-medium">{transfer.toTeamName || 'Bilinmiyor'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Transfer Özeti */}
              <div className="mt-8 bg-[#2a2a2a] rounded-lg p-4">
                <h3 className="text-lg font-bold mb-4">Transfer Özeti</h3>
                <div className="grid grid-cols-3 gap-4">
                  <div className="text-center">
                    <div className="text-2xl font-bold">
                      {transferHistory.transferHistory.filter(transfer => transfer.transferFrom || transfer.transferTo).length}
                    </div>
                    <div className="text-sm text-gray-400">Toplam Transfer</div>
                  </div>
                  <div className="text-center">
                    <div className="text-2xl font-bold text-green-400">
                      {(transferHistory.transferHistory
                        .filter(transfer => transfer.transferFee > 0)
                        .reduce((sum, transfer) => sum + (transfer.transferFee || 0), 0) / 1000000).toFixed(1)}M €
                    </div>
                    <div className="text-sm text-gray-400">Toplam Değer</div>
                  </div>
                  <div className="text-center">
                    <div className="text-2xl font-bold text-yellow-400">
                      {transferHistory.transferHistory.filter(t => t.type === 2).length}
                    </div>
                    <div className="text-sm text-gray-400">Kiralık Sayısı</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-white text-center">Transfer verisi bulunamadı</div>
          )}
        </div>

      

        {/* Milli Takım İstatistikleri - Lazy Loading */}
        <div ref={nationalRef} className="min-h-[300px] bg-[#1a1a1a] rounded-lg p-6 mt-4">
          {!loadNational ? (
            <LoadingPlaceholder />
          ) : nationalStats?.team && nationalStats?.statistics ? (
            <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-xl font-bold mb-4">Milli Takım İstatistikleri</h2>
              <div className="grid grid-cols-2 gap-8">
                {/* Milli Takım Detayları */}
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <div className="flex items-center gap-4 mb-6">
                    <img 
                      src=""
                      alt=""
                      className="w-16 h-16 object-contain bg-white rounded-lg p-2"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-team-logo.png';
                      }}
                    />
                    <div>
                      <h3 className="font-bold text-lg">{nationalStats.team.name}</h3>
                      <p className="text-sm text-gray-400">Milli Takım</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    {nationalStats.firstMatchTimestamp && (
                      <div className="bg-[#1a1a1a] rounded-lg p-3">
                        <div className="text-sm text-gray-400">İlk Maç</div>
                        <div className="font-bold mt-1">
                          {new Date(nationalStats.firstMatchTimestamp * 1000).toLocaleDateString('tr-TR', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Toplam İstatistikler */}
                <div className="bg-[#2a2a2a] rounded-lg p-4">
                  <h3 className="text-lg font-bold mb-4">Toplam İstatistikler</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-3">
                      <div className="flex items-center gap-3">
                        <img src="/icons/mac.png" alt="Maç" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Toplam Maç</span>
                          <span className="font-bold">{nationalStats.statistics[0]?.appearances || 0}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <img src="/icons/goal.png" alt="Gol" className="w-5 h-5" />
                        <div className="flex justify-between flex-1">
                          <span className="text-gray-400">Toplam Gol</span>
                          <span className="font-bold">{nationalStats.statistics[0]?.goals || 0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-white text-center">Milli takım verisi bulunamadı</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerDetail; 