import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { config } from '../config';
import LiveMatchRow from '../components/LiveMatchRow';

const Live = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animationData, setAnimationData] = useState(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const ALLOWED_TOURNAMENT_IDS = [7, 679, 17, 8, 35, 23, 34, 17015, 52, 325, 384, 16, 357,238];

  const fetchLiveMatches = async () => {
    try {
      const options = {
        method: 'GET',
        url: 'https://footapi7.p.rapidapi.com/api/matches/live',
        headers: {
          'X-RapidAPI-Key': 'a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023',
          'X-RapidAPI-Host': 'footapi7.p.rapidapi.com'
        }
      };

      const response = await axios.request(options);
      console.log('API Response:', response.data);

      if (response.data.events) {
        const formattedMatches = response.data.events
          .filter(match => 
            match.tournament?.uniqueTournament?.id && 
            ALLOWED_TOURNAMENT_IDS.includes(match.tournament.uniqueTournament.id)
          )
          .map(match => ({
            id: match.id,
            tournament: {
              name: match.tournament?.name || 'Bilinmeyen Turnuva',
              uniqueTournament: {
                id: match.tournament?.uniqueTournament?.id
              }
            },
            homeTeam: {
              id: match.homeTeam?.id,
              name: match.homeTeam?.name || 'Ev Sahibi'
            },
            awayTeam: {
              id: match.awayTeam?.id,
              name: match.awayTeam?.name || 'Deplasman'
            },
            homeScore: {
              current: match.homeScore?.current || 0
            },
            awayScore: {
              current: match.awayScore?.current || 0
            },
            status: match.status || { code: 0 },
            startTimestamp: match.startTimestamp,
            roundInfo: match.roundInfo || { round: 'Bilinmiyor' },
            time: match.time || {},
            isLive: true
          }));

        const liveMatches = formattedMatches.filter(match => 
          match.status.type === 'inprogress' || 
          match.status.type === 'halftime' ||
          match.status.description === 'Pending'
        );

        setMatches(liveMatches);
      }
    } catch (err) {
      console.error('API Hatası:', err);
      setError('Canlı maç verisi alınamadı. Lütfen daha sonra tekrar deneyiniz.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const response = await fetch('/load.json');
        const data = await response.json();
        setAnimationData(data);
      } catch (err) {
        console.error("Animasyon yüklenemedi:", err);
      }
    };

    loadAnimation();
    fetchLiveMatches();

    const interval = setInterval(fetchLiveMatches, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-[#121212]">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold text-white mb-6 text-center">Canlı Maçlar</h1>

        {loading && animationData && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        )}
        
        {error && (
          <div className="text-red-500 text-center p-4 bg-[#1a1a1a] rounded-lg">
            {error}
          </div>
        )}
        
        {!loading && !error && matches.length === 0 && (
          <div className="text-center text-white p-8 bg-[#1a1a1a] rounded-lg">
            Şu anda canlı maç bulunmamaktadır.
          </div>
        )}

        {!loading && !error && matches.length > 0 && (
          <div className="space-y-2">
            {matches.map((match) => (
              <LiveMatchRow key={match.id} match={match} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Live;