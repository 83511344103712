import React from 'react';

const StatisticsTable = ({ statistics, teamLogos, className }) => {
  // İstatistikleri kategorilere göre sıralama
  const sortStatistics = (stats, key) => {
    return [...stats].sort((a, b) => (b[key] || 0) - (a[key] || 0));
  };

  // Her kategori için ayrı tablo oluşturma fonksiyonu
  const StatTable = ({ title, data, columns }) => (
    <div className="bg-[#1d1d1d] p-3 rounded-lg">
      <h3 className="text-white text-lg font-bold mb-2">{title}</h3>
      <table className={`w-full ${className}`}>
        <thead>
          <tr className="bg-[#1d1d1d]">
            <th className="px-4 py-2 text-center text-gray-400">Sıra</th>
            <th className="px-4 py-2 text-left text-gray-400">Oyuncu</th>
            {columns.map((col) => (
              <th key={col.key} className="px-4 py-2 text-center text-gray-400">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={`${item.player.id}-${index}`}
              className={`border-b border-gray-800 ${index % 2 === 0 ? "bg-[#0d0d0d]" : "bg-[#171717]"}`}
              onClick={() => window.location.href = `/team/${item.team.id}`}
            >
              <td className="py-2 text-center text-white">{index + 1}</td>
              <td className="py-2">
                <div className="flex items-center gap-3">
                  {teamLogos[item.team.id] && (
                    <img 
                      src={teamLogos[item.team.id]} 
                      alt={item.team.name}
                      className="w-10 h-10 object-contain"
                    />
                  )}
                  <div>
                    <div className="text-white">{item.player.name}</div>
                    <div className="text-sm text-gray-400">{item.team.name}</div>
                  </div>
                </div>
              </td>
              {columns.map(col => (
                <td key={col.key} className="text-center text-gray-300 py-2">
                  {col.format ? col.format(item[col.key]) : item[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // En iyi 10 oyuncuyu al
  const TOP_N = 10;

  return (
    <div className="space-y-4 bg-black p-4 rounded-lg">
      {/* Gol Krallığı */}
      <StatTable 
        title="Gol Krallığı"
        data={sortStatistics(statistics, 'goals').slice(0, TOP_N)}
        columns={[
          { key: 'goals', label: 'Gol' }
        ]}
      />

      {/* Asist Krallığı */}
      <StatTable 
        title="Asist Krallığı"
        data={sortStatistics(statistics, 'assists').slice(0, TOP_N)}
        columns={[
          { key: 'assists', label: 'Asist' }
        ]}
      />

      {/* En İyi Performans */}
      <StatTable 
        title="En İyi Performans"
        data={sortStatistics(statistics, 'rating').slice(0, TOP_N).map(stat => ({
          ...stat,
          rating: stat.rating + 0.10
        }))}
        columns={[
          { key: 'rating', label: 'Rating', format: (val) => val?.toFixed(2) || 0 }
        ]}
      />

      {/* Başarılı Dripling */}
      <StatTable 
        title="En Çok Dripling Yapanlar"
        data={sortStatistics(statistics, 'successfulDribbles').slice(0, TOP_N)}
        columns={[
          { key: 'successfulDribbles', label: 'Başarılı Dripling' }
        ]}
      />

      {/* Pas İsabeti */}
      <StatTable 
        title="En İyi Pas İsabeti"
        data={sortStatistics(statistics, 'accuratePassesPercentage').slice(0, TOP_N)}
        columns={[
          { 
            key: 'accuratePassesPercentage', 
            label: 'Pas İsabeti %',
            format: (val) => `${val?.toFixed(1) || 0}%`
          }
        ]}
      />
    </div>
  );
};

export default StatisticsTable;
